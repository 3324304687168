/* You can add global styles to this file, and also import other style files */
.disclaimer-dialog-container .mat-dialog-container .mat-dialog-content p { 
    color: rgb(0, 0, 0);
    font-family: verdana, arial, helvetica, sans-serif;
    font-size: 11px;
    text-decoration: none;
}

.disclaimer-dialog-container .mat-dialog-container .mat-dialog-title {
    margin: 0 0 10px;
    display: block;
    font-family: verdana, arial, helvetica, sans-serif;
}

.disclaimer-dialog-container .mat-dialog-container .mat-raised-button {
    background-color: rgb(68, 135, 233);
    color: rgb(255, 255, 255);
    font-family: verdana, arial, helvetica, sans-serif;
}
